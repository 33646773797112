import { get } from 'lodash';

export const WORKSPACE_DOMAIN_TO_SUBDOMAIN_MAP = {
	'workspace-dev': 'pq-web',
	'workspace-stg': 'livedev2',
	workspace: 'live2',
};

export const REGION_DOMAINS = {
	local: {
		WEB_URL: 'http://localhost:3020',
		BASE_URL: 'http://localhost:8088',
	},
	dev: {
		WEB_URL: 'https://pq-web.tectusdreamlab.com',
		BASE_URL: 'https://pq-dev.tectusdreamlab.com',
	},
	stg: {
		WEB_URL: 'https://livedev2.proceq.com',
		BASE_URL: 'https://mgt.livedev2.proceq.com',
	},
	prod: {
		WEB_URL: 'https://live2.proceq.com',
		BASE_URL: 'https://mgt.live2.proceq.com',
	},
	'dev-cn': {
		WEB_URL: 'https://pq-web-cn.tectusdreamlab.com',
		BASE_URL: 'https://pq-cn.tectusdreamlab.com',
	},
	'stg-cn': {
		WEB_URL: 'https://livedev2.proceq.cn.com',
		BASE_URL: 'https://mgt.livedev2.proceq.cn.com',
	},
	'prod-cn': {
		WEB_URL: 'https://live2.proceq.cn.com',
		BASE_URL: 'https://mgt.live2.proceq.cn.com',
	},
};

export const EAGLE_CLOUD_DOMAINS = {
	local: 'https://workspace-dev.screeningeagle.com',
	dev: 'https://workspace-dev.screeningeagle.com',
	'dev-cn': 'https://workspace-dev.screeningeagle.com',
	stg: 'https://workspace-stg.screeningeagle.com',
	'stg-cn': 'https://workspace-stg.screeningeagle.com',
	prod: 'https://workspace.screeningeagle.com',
	'prod-cn': 'https://workspace.screeningeagle.com',
};

const EAGLE_ID_DOMAINS = {
	local: 'https://id-dev.screeningeagle.com',
	dev: 'https://id-dev.screeningeagle.com',
	stg: 'https://id-stg.screeningeagle.com',
	prod: 'https://id.screeningeagle.com',
	'dev-cn': 'https://id-dev.screeningeagle.com',
	'stg-cn': 'https://id-stg.screeningeagle.com',
	'prod-cn': 'https://id.screeningeagle.com',
};

const WEB_URL = get(REGION_DOMAINS[process.env.SERVER_TYPE], 'WEB_URL', 'http://localhost:3020');
export const BASE_URL =
	process.env.BASE_URL || get(REGION_DOMAINS[process.env.SERVER_TYPE], 'BASE_URL', 'https://mgt.livedev2.proceq.com');
export const EAGLE_CLOUD_URL = EAGLE_CLOUD_DOMAINS[process.env.SERVER_TYPE];
export const EAGLE_CLOUD_API = `${EAGLE_CLOUD_URL}/api/v1`;
const EAGLE_ID_URL = EAGLE_ID_DOMAINS[process.env.SERVER_TYPE] || EAGLE_ID_DOMAINS.dev;

export const EAGLE_ID_RESET = `${EAGLE_ID_DOMAINS[process.env.SERVER_TYPE]}/resetPassword`;

let PROCEQ_DOMAIN = 'livedev.proceq';
switch (process.env.SERVER_TYPE) {
	case 'dev':
		PROCEQ_DOMAIN = 'pq-eq-2.tectusdreamlab';
		break;
	case 'dev-cn':
		PROCEQ_DOMAIN = 'pq-cn-2.tectusdreamlab';
		break;
	case 'stg':
		PROCEQ_DOMAIN = 'livedev.proceq';
		break;
	case 'stg-cn':
		PROCEQ_DOMAIN = 'livedev.proceq.cn';
		break;
	case 'prod':
		PROCEQ_DOMAIN = 'live.proceq';
		break;
	case 'prod-cn':
		PROCEQ_DOMAIN = 'live.proceq.cn';
		break;
	default:
}

const DEFAULT_PROCEQ_API_URL = `https://${PROCEQ_DOMAIN}.com/api/`;

const PROCEQ_REGIONS_API_URLS = {
	EU: `https://${PROCEQ_DOMAIN}.com/api/`,
	SG: `https://srv2.${PROCEQ_DOMAIN}.com/api/`,
	US: `https://srv3.${PROCEQ_DOMAIN}.com/api/`,
	JP: `https://srv4.${PROCEQ_DOMAIN}.com/api/`,
	CN: `https://${PROCEQ_DOMAIN}.cn.com/api/`,
};

switch (process.env.SERVER_TYPE) {
	case 'prod-cn':
	case 'stg-cn':
		PROCEQ_REGIONS_API_URLS.CN = `https://${PROCEQ_DOMAIN}.com/api/`;
		break;
	default:
}
/* TDS CLOUD */
const API_VERSION = '/api';
const APP_URL = `${BASE_URL}${API_VERSION}`;
const prefix = process.env.SERVER_TYPE === 'local' ? 'http://' : 'https://';
const NONMNGT_URL = `${prefix}{non_mngt_url}${API_VERSION}`;

const TOKEN = `${APP_URL}/token`;
const AUTH = `${APP_URL}/auth`;
const PROFILE = `${APP_URL}/account`;
export const CONTRACTS = `${APP_URL}/contracts`;

export const LICENSES = `${APP_URL}/licenses`;
export const AUDIT_HISTORY = `${APP_URL}/audit_history`;
const FEATURES = `${APP_URL}/feature`;
const FEATURE_BY_PROD = `${APP_URL}/feature/product`;
const TIERS = `${APP_URL}/tier`;
const TIER_FEATURES = `${APP_URL}/tier/features`;
const TIER_FEATURES_ALL = `${APP_URL}/tier/features/all`;
const PRODUCT = `${APP_URL}/product`;
const ACCOUNTS = `${APP_URL}/account`;
const ROLES = `${APP_URL}/role`;
const METADATA_FIELDS = `${APP_URL}/meta`;
const CONFIG_FIELDS = `${APP_URL}/config`;
const PROBES = `${APP_URL}/probes`;
const DONGLES = `${APP_URL}/dongles`;
const FIRMWARE = `${APP_URL}/firmware`;
const BU = `${APP_URL}/bucontacts`;
const ADMIN = `${APP_URL}/admin`;
const EVENTS = `${APP_URL}/events`;
const I18N = `${APP_URL}/i18n`;
const EAGLE_ID = `${APP_URL}/eagleid`;
const USER = `${APP_URL}/user`;

const ANALYTICS = `${NONMNGT_URL}/analytics`;
const MIGRATION = `${NONMNGT_URL}/migrator`;
const FOLDERS = `${NONMNGT_URL}/folders`;
const MEASUREMENTS = `${NONMNGT_URL}/measurements`;
const NOTIFICATION = `${EAGLE_CLOUD_API}/notification`;
const GENERIC_MEASUREMENTS = `${NONMNGT_URL}/generic`;

const EAGLE_ID_OAUTH = `${EAGLE_ID_URL}/api/v1/oauth`;

export const isProduction = ['stg', 'stg-cn', 'prod', 'prod-cn'].includes(process.env.SERVER_TYPE);
export const isLive = ['prod', 'prod-cn'].includes(process.env.SERVER_TYPE);
export const serverType = process.env.SERVER_TYPE;
export const serverTypeLabel = {
	local: 'local',
	dev: 'development',
	stg: 'staging',
	prod: 'production',
}[process.env.SERVER_TYPE];

export default {
	WEB_URL,

	/* TDS CLOUD */
	SIGN_UP: `${AUTH}/signup`,
	CONFIRM: `${AUTH}/confirm`,

	APP_CONFIG: `${METADATA_FIELDS}/appconfigs`,

	SIGN_IN: `${TOKEN}/get`,
	SIGN_OUT: `${TOKEN}/revoke`,
	REFRESH_TOKEN: `${TOKEN}/refresh`,
	ASSUME_USER: `${TOKEN}/assume`,

	AUTH_TOKEN: `${EAGLE_ID}/oauth/token`,

	FORGOT_PASSWORD: `${PROFILE}/forgotpassword`,
	RESET_PASSWORD: `${PROFILE}/resetpassword`,
	VALIDATE_PASSWORD_POLICY: `${PROFILE}/validatepasswordpolicy`,

	GET_USERS: `${USER}`,

	DASHBOARD: `${ANALYTICS}/user/measurements`,
	DASHBOARD_LOCATION: `${ANALYTICS}/user/location`,
	USER_ANALYTICS: `${ADMIN}/usermeasurements`,
	USER_ANALYTICS_LOCATION: `${ADMIN}/userlocations`,
	REGIONAL_ANALYTICS: `${ADMIN}/productanalytics`,
	LOCATION_ANALYTICS: `${ADMIN}/locationanalytics`,
	UPDATE_VERSION: `${ADMIN}/appversion`,
	GENERATE_ANALYTICS_REPORT: `${ADMIN}/generateanalyticsreport`,
	GENERATE_ANALYTICS_REPORT_ASYNC: `${ADMIN}/generateanalyticsreport/async`,

	NOTIFICATION_SCHEDULED: `${NOTIFICATION}/scheduled`,
	NOTIFICATION_SCHEDULED_ITEM: `${NOTIFICATION}/scheduled/{id}`,

	GET_PACKAGES: `${MIGRATION}/packages`,

	GET_SELF: `${PROFILE}/`,
	UPDATE_SELF: `${PROFILE}/`,
	SET_PORTRAIT: `${PROFILE}/picture`,

	GET_FEATURES: `${FEATURES}`,
	GET_FEATURE_BY_PROD: `${FEATURE_BY_PROD}`,
	ADD_EDIT_FEATURE: `${FEATURES}`,
	DELETE_FEATURE: `${FEATURES}`,

	GET_TIERS: `${TIERS}`,
	ADD_EDIT_TIER: `${TIERS}`,
	DELETE_TIER: `${TIERS}`,

	GET_TIER_FEATURES: `${TIER_FEATURES}`,
	GET_ALL_TIER_FEATURES: `${TIER_FEATURES_ALL}`,

	GET_PRODUCT: `${PRODUCT}`,
	GET_PRODUCT_AVAILABLE: `${PRODUCT}/available`,

	GET_LICENSES: `${LICENSES}/`,
	GET_INSIGHTS_FREE_LICENSES: `${LICENSES}/insightsFreeTier`,
	GET_LICENSES_BY_CONTRACT_ID: `${LICENSES}/getByContractId/{key}`,
	DOWNLOAD_LICENSES_BY_CONTRACT_ID: `${LICENSES}/downloadLicensesByContract/{key}`,
	DOWNLOAD_LICENSES: `${LICENSES}/downloadLicenses`,
	EXPORT_LICENSES: `${LICENSES}/export`,
	CREATE_FREE_TRIAL_LICENSE: `${EAGLE_ID_URL}/api/v1/user/license/trial/activate/?product={product}`,
	UPDATE_LICENSE: `${LICENSES}/{key}`,
	GET_CONTRACTS: `${CONTRACTS}/`,
	CONTRACTS_HISTORY: `${CONTRACTS}/history/{key}`,
	DOCUMENT_HISTORY: `${AUDIT_HISTORY}/{type}/{key}`,
	EXPORT_DOCUMENT_HISTORY: `${AUDIT_HISTORY}/export/{type}/{key}`,
	UPDATE_WIFI_CONTRACT: `${CONTRACTS}/v1/{key}`,
	CREATE_WIFI_CONTRACT: `${CONTRACTS}/v1/`,
	UPDATE_TEMP_WIFI_CONTRACT: `${CONTRACTS}/v2/{key}`,
	CREATE_TEMP_WIFI_CONTRACT: `${CONTRACTS}/v2/`,
	UPDATE_BLUETOOTH_CONTRACT: `${CONTRACTS}/bluetooth/{key}`,
	CREATE_BLUETOOTH_CONTRACT: `${CONTRACTS}/bluetooth/`,
	UPDATE_BLUETOOTH_CONTRACT_ADMIN: `${CONTRACTS}/bluetooth/admin/{key}`,
	CREATE_BLUETOOTH_CONTRACT_ADMIN: `${CONTRACTS}/bluetooth/admin/`,
	EXPORT_CONTRACTS: `${CONTRACTS}/export/`,
	EXPORT_TIERS: `${TIERS}/export/`,
	EXPORT_FEATURES: `${FEATURES}/export/`,
	UNLOCK_CONTRACTS: `${CONTRACTS}/bluetooth/unpair/`,
	DELETE_CONTRACTS: `${CONTRACTS}/`,
	NOTIFY_CONTRACT_EXPIRY: `${CONTRACTS}/notifyexpiary`,
	GET_LICENSE_NUMBER_SUGGESTION: `${CONTRACTS}/field/suggest`,

	GET_BUS: `${BU}/`,
	UPDATE_BU: `${BU}/`,
	CREATE_BU: `${BU}/`,
	DELETE_BU: `${BU}/{key}`,

	GET_ACCOUNT: `${ACCOUNTS}/?filterKey=id&filterValue={key}`,
	GET_ACCOUNTS: `${ACCOUNTS}/all/`,
	UPDATE_ACCOUNT: `${ACCOUNTS}/{key}`,
	EXPORT_ACCOUNTS_CSV: `${ACCOUNTS}/export/`,
	CREATE_ACCOUNT: `${ACCOUNTS}/create/`,
	ACTIVATE_ACCOUNT: `${ACCOUNTS}/{key}/activate/`,
	CHANGE_ACCOUNT_PASSWORD: `${ACCOUNTS}/{key}/updatepassword/`,
	DELETE_ACCOUNT: `${ACCOUNTS}/`,
	UPDATE_PASSWORD_ACCOUNT: `${ACCOUNTS}/{key}/updatepassword/`,
	UNBLOCK_ACCOUNT: `${ACCOUNTS}/resetUserEmailStatus`,
	REVERIFY_ACCOUNT: `${ACCOUNTS}/sendconfirmation`,
	SEND_EAGLE_ID_NOTIFICATION: `${ACCOUNTS}/eagleusernotification`,

	GRANT_ROLES: `${ROLES}/`,

	METADATA_FIELDS: `${METADATA_FIELDS}/`,
	GET_VERSIONS: `${METADATA_FIELDS}/appversion/v2`,
	GET_DEPENDENCY_VERSIONS: `${CONFIG_FIELDS}/dependency`,

	GET_PROBES: `${PROBES}/`,
	EXPORT_PROBES: `${PROBES}/export/`,

	GET_DONGLES: `${DONGLES}/`,
	EXPORT_DONGLES: `${DONGLES}/export/`,

	GET_FIRMWARES: `${FIRMWARE}/all`,
	DOWNLOAD_FIRMWARE: `${FIRMWARE}/downloadurl`,
	DELETE_FIRMWARE: `${FIRMWARE}`,

	GET_SUMMARY: `${MEASUREMENTS}/summary/`,
	GET_FOLDERS: `${FOLDERS}/`,
	GET_MEASUREMENTS: `${MEASUREMENTS}/all/`,
	GET_GENERIC_MEASUREMENTS: `${GENERIC_MEASUREMENTS}/all/`,

	IMPORT_MEASUREMENTS: `${NONMNGT_URL}/import`,
	EXPORT_REPORTS: `${NONMNGT_URL}/export`,
	EXPORT_REPORT_AS_URL: `${NONMNGT_URL}/longrunningjob/export/init`,

	DOWNLOAD_FILE: `${NONMNGT_URL}/files/download`,
	GET_SIGNED_S3_URL: `${NONMNGT_URL}/files/signeds3url`,
	LOGBOOK: `${NONMNGT_URL}/logs`,
	MEASUREMENTS: `${NONMNGT_URL}/measurements/`,
	MEASUREMENT_READINGS: `${NONMNGT_URL}/readings/`,
	ATTACHMENTS: `${NONMNGT_URL}/attachments/`,
	SHARE_MEASUREMENTS: `${NONMNGT_URL}/sharing/v3`,
	GET_SHARED_MEASUREMENTS: `${NONMNGT_URL}/sharing/v2`,
	GET_SHARED_PROFILES: `${ACCOUNTS}/profiles`,
	RENEW_SHARED_MEASUREMENT: `${API_VERSION}/sharing/renew`,
	GENERATE_DEMO_FILES: `${ADMIN}/generatedemofiles`,
	WIPEOUT_DATA: `${NONMNGT_URL}/sync/clean`,
	CLEAR_CACHE: `${ADMIN}/cache`,

	GET_EVENTS: `${EVENTS}`,
	GET_EVENT_FILTERS: `${EVENTS}/filters`,

	EXPORT_TRANSLATIONS: `${I18N}/export`,
	IMPORT_TRANSLATIONS: `${I18N}/import`,

	/* PROCEQ CLOUD */
	DEFAULT_PROCEQ_API_URL,
	PROCEQ_REGIONS_API_URLS,
	PROCEQ_API_URL: '{proceq_api_url}',
	PROCEQ_AUTH: '{proceq_api_url}token/get/',
	PROCEQ_SIGN_OUT: '{proceq_api_url}token/logout/',
	PROCEQ_CREATE_ACCOUNT: '{proceq_api_url}register/account/',
	PROCEQ_FORGOT_PASSWORD: '{proceq_api_url}password/forgot/',
	PROCEQ_GET_ACCOUNT: '{proceq_api_url}account/profile/getinfo/',
	PROCEQ_GET_SESSION_INFO: '{proceq_api_url}token/sessioninfo/',
	PROCEQ_CHANGE_ACTIVE_GROUP: '{proceq_api_url}account/change/group/',
	PROCEQ_UPDATE_ACCOUNT: '{proceq_api_url}account/profile/set/',
	PROCEQ_UPDATE_PASSWORD_ACCOUNT: '{proceq_api_url}account/change/password/',
	PROCEQ_GET_FOLDERS: '{proceq_api_url}folder/getallactive/',
	PROCEQ_GET_MEASUREMENTS: '{proceq_api_url}dataseries/getlist/',
	PROCEQ_GET_CUSTOM_DATA: '{proceq_api_url}customdata/getlist/?type_data={type_data}',
	PROCEQ_SHARE_BY_URL: '{proceq_api_url}share/url/series/',
	PROCEQ_EXPORT_CSV_MEASUREMENTS: '{proceq_api_url}export/csv/datameasures/',
	PROCEQ_EXPORT_PDF_MEASUREMENTS: '{proceq_api_url}export/pdf/datameasures/',
	PROCEQ_EXPORT_PDF_SCHMIDT_REGIONS_EU: '{proceq_api_url}export/pdfregions/datameasures/?type_region=eu',
	PROCEQ_EXPORT_PDF_SCHMIDT_REGIONS_CHINA: '{proceq_api_url}export/pdfregions/datameasures/?type_region=china',
	PROCEQ_EXPORT_PDF_SCHMIDT_REGIONS_UNIFORMITY:
		'{proceq_api_url}export/pdfregions/datameasures/?type_region=uniformity',
	PROCEQ_EXPORT_CSV_DEBUG_1_MEASUREMENTS: '{proceq_api_url}export/csvdebug/datameasures/',
	PROCEQ_EXPORT_CSV_DEBUG_2_MEASUREMENTS: '{proceq_api_url}export/csvdebug2/datameasures/',
	PROCEQ_GET_LOGBOOK: '{proceq_api_url}dataseries/logbook/getinfo/',
	PROCEQ_FILE_DOWNLOADS: '{proceq_api_url}file/download/',
	PROCEQ_REQUEST_FILE_DOWNLOAD: '{proceq_api_url}file/todownload/',
	PROCEQ_DASHBOARD_GET_DATA: '{proceq_api_url}dashboard/get/',
	PROCEQ_DASHBOARD_ADMIN_GET_DATA: '{proceq_api_url}dashboard/proceq/get/',
	PROCEQ_GET_CONTRACTS: '{proceq_api_url}snassigner/serials/getlist/',
	PROCEQ_EXPORT_CONTRACTS_CSV: '{proceq_api_url}snassigner/serials/getcsvlist/',
	PROCEQ_CREATE_CONTRACT: '{proceq_api_url}snassigner/serials/addcontract/',
	PROCEQ_UPDATE_CONTRACT: '{proceq_api_url}snassigner/serials/edit/',
	PROCEQ_CREATE_CONTRACT_ADMIN: '{proceq_api_url}snassigner/serialsadmin/addcontract/',
	PROCEQ_UPDATE_CONTRACT_ADMIN: '{proceq_api_url}snassigner/serialsadmin/edit/',
	PROCEQ_UNLOCK_CONTRACT_ADMIN: '{proceq_api_url}snassigner/serialsadmin/unlock/',
	PROCEQ_GET_ACCOUNTS: '{proceq_api_url}admin/users/getlist/',
	PROCEQ_GRANT_BU_ROLE: '{proceq_api_url}admin/roles/assign/bu/',
	PROCEQ_REVOKE_BU_ROLE: '{proceq_api_url}admin/roles/deassign/bu/',
	PROCEQ_GRANT_ADMIN_ROLE: '{proceq_api_url}admin/roles/assign/admin/',
	PROCEQ_REVOKE_ADMIN_ROLE: '{proceq_api_url}admin/roles/deassign/admin/',
	PROCEQ_GRANT_DEVELOPER_ROLE: '{proceq_api_url}admin/roles/assign/developer/',
	PROCEQ_REVOKE_DEVELOPER_ROLE: '{proceq_api_url}admin/roles/deassign/developer/',
	PROCEQ_EXPORT_ACCOUNTS_CSV: '{proceq_api_url}admin/users/getcsvlist/',
	PROCEQ_ACTIVATE_ACCOUNT: '{proceq_api_url}admin/users/confirm/email/',
	PROCEQ_ADMIN_UPDATE_PASSWORD_ACCOUNT: '{proceq_api_url}admin/users/change/password/',
	PROCEQ_DELETE_ACCOUNT: '{proceq_api_url}admin/delete/user/',
	PROCEQ_DELETE_ACCOUNT_DATA: '{proceq_api_url}admin/delete/usersdata/',
	PROCEQ_EXPORT_USERACTIVITY_CSV: '{proceq_api_url}admin/report/history/',
	PROCEQ_GET_BUS_INFO: '{proceq_api_url}admin/businfo/getlist/',
	PROCEQ_ADD_BU_INFO: '{proceq_api_url}admin/businfo/add/',
	PROCEQ_EDIT_BU_INFO: '{proceq_api_url}admin/businfo/edit/',
	PROCEQ_EDIT_BUS_INFO_CSV: '{proceq_api_url}admin/businfo/getcsvlist/',
	PROCEQ_GET_HISTORY_LOGS: '{proceq_api_url}history/{type}/getlist/',
	PROCEQ_GET_HISTORY_CSV: '{proceq_api_url}history/{type}/getcsvlist/',
	PROCEQ_ADMIN_REPORTS: '{proceq_api_url}admin/reports/datamining/',
	PROCEQ_LOGIN_AS_USER: '{proceq_api_url}admin/loginasuser/',
	PROCEQ_RESET_PASSWORD: '{proceq_api_url}password/setforgot/',
	PROCEQ_MAIL_ACTIONS: '{proceq_api_url}mail/actions/general/',
	PROCEQ_GET_TRACK_USER_LOG: '{proceq_api_url}dev/log/track/getlist/',
	PROCEQ_SHOW_TRACK_USER_SUBMIT: '{proceq_api_url}dev/log/track/entries/',

	CHECK_EAGLE_ID_AUTH: `${EAGLE_ID_OAUTH}/authorization`,
	SET_EAGLE_ID_AUTH: `${EAGLE_ID_OAUTH}/verify`,
	EAGLE_ID_LOGOUT: `${EAGLE_ID_OAUTH}/logout`,

	RESET_2_FACTOR_AUTHENTICATION: `${EAGLE_ID_URL}/api/v1/user/{id}/2fa/reset/`,
	RESET_LOGIN_ATTEMPTS: `${EAGLE_ID_URL}/api/v1/user/{id}/login/attempt/reset/`,
};
