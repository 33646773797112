// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, withStateHandlers } from 'recompose';
import { Form, Button, Checkbox, Row, Col, message } from 'antd';
import axios from 'axios';
import type { FormProps } from 'antd/lib/form';
import { FormattedMessage, injectIntl } from 'react-intl';
import URL from 'apis/urls';
import { request } from 'actions/ajax';
import { getInitialRange } from 'constants/dateRange';
import { getTextValue } from 'utils/registryHelper';
import SmarterSelect from '../shared/SmarterFormItems/Select';
import layoutStyles from '../layout/Layout.styl';
import styles from './AnalyticsReport.styl';
import DateRange from '../shared/DateRange';

const AnalyticsReportConfigs = [
	{
		type: 'UserMeasuringDeviceDataSummary',
		label: 'User Measuring Device Data Summary',
	},
	{
		type: 'GP8x00Details',
		label: 'GP8x00 Details',
	},
];

const globalRegisterForRegions = 'regionsnamesregionalanalytics';
const allRegions = getTextValue([globalRegisterForRegions])
	.map(({ value }) => value)
	.filter(v => v !== 'All');

export const AnalyticsReportPage = ({
	loading,
	generateReport,
	form,
	cancel,
}: {
	loading: boolean,
	generateReport: Function,
	form: FormProps,
	cancel: Function,
}) => (
	<div className={layoutStyles.container}>
		<h2 className="label-statistics">
			<FormattedMessage id="App.DashboardFilters" />
		</h2>
		<Form onSubmit={generateReport}>
			<Row gutter={[16, 16]} type="flex">
				<Col span={6}>
					<Form.Item label={<FormattedMessage id="App.AnalyticsRegion" />}>
						{form.getFieldDecorator('regions', {
							rules: [{ required: true }],
						})(<SmarterSelect mode="multiple" globalRegister={globalRegisterForRegions} />)}
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item label={<FormattedMessage id="App.DashboardDateRange" />}>
						{form.getFieldDecorator('dates', {
							initialValue: getInitialRange(),
						})(<DateRange />)}
					</Form.Item>
				</Col>
				<Col span={12} />
				<Col span={6}>
					<Form.Item label={<FormattedMessage id="App.AnalyticsReportTypes" />}>
						{form.getFieldDecorator('reportTypes', {
							rules: [{ required: true }],
						})(
							<Checkbox.Group>
								{AnalyticsReportConfigs.map((config, index) => {
									return (
										<div key={index}>
											<Checkbox value={config.type}>{config.label}</Checkbox>
										</div>
									);
								})}
							</Checkbox.Group>
						)}
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Button type="primary" htmlType="submit" loading={loading}>
					<FormattedMessage id="App.GenerateAnalyticsReport" />
				</Button>
				{loading && (
					<Button type="default" onClick={cancel} className={styles.cancel_button}>
						<FormattedMessage id="App.AnalyticsRegionExportCancel" />
					</Button>
				)}
			</Row>
		</Form>
	</div>
);

export default compose(
	injectIntl,
	Form.create({}),
	withStateHandlers(
		{
			loading: false,
			cancelSource: axios.CancelToken.source(),
		},
		{
			startLoading: state => () => ({
				...state,
				loading: true,
			}),
			finishLoading: state => () => ({
				...state,
				loading: false,
			}),
			cancel: ({ cancelSource, ...state }, { intl }) => () => {
				cancelSource.cancel();
				message.error(intl.formatMessage({ id: 'App.AnalyticsOperationCancelled' }));
				return { ...state, cancelSource: axios.CancelToken.source(), loading: false };
			},
			cancelOnlyToken: ({ cancelSource, ...state }) => () => {
				cancelSource.cancel();
				return { ...state, cancelSource: axios.CancelToken.source() };
			},
		}
	),
	connect(null, (dispatch: Function, props) => ({
		generateReport: e => {
			if (e) e.preventDefault();
			props.startLoading();
			let reqBody = {};
			props.form.validateFields((err, formValues) => {
				if (err) {
					props.finishLoading();
					return;
				}

				const regionValues = formValues.regions.includes('All') ? allRegions : formValues.regions;
				reqBody = {
					regions: regionValues,
					from: formValues.dates.from,
					to: formValues.dates.to,
					reportTypes: formValues.reportTypes,
				};
				dispatch(
					request({
						method: 'POST',
						setLoading: props.setLoading,
						url: URL.GENERATE_ANALYTICS_REPORT_ASYNC,
						params: reqBody,
						returnResponseHeaders: true,
						options: {
							cancelToken: props.cancelSource.token,
						},
					})
				)
					.then(response => {
						props.finishLoading();
						window.open(response.data.url, '_blank');
					})
					.catch(() => {
						props.finishLoading();
						props.cancelOnlyToken();
					});
			});
		},
	}))
)(AnalyticsReportPage);
