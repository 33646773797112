// @flow
import React from 'react';
import type { Match } from 'react-router';
import Events from './Events';
import UserAnalytics from './UserAnalytics';
import RegionalAnalytics from './RegionalAnalytics';
import PageNotFound from '../error/PageNotFound';
import AnalyticsReport from './AnalyticsReport';

export const Analytics = ({ match }: { match: Match }) => {
	switch (match.params.page) {
		case 'events':
			return <Events />;
		case 'user':
			return <UserAnalytics />;
		case 'regional':
			return <RegionalAnalytics />;
		case 'reports':
			return <AnalyticsReport />;
		default:
			return <PageNotFound />;
	}
};

export default Analytics;
